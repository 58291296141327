import { useState } from 'react';
import './App.css';
import Dev from './pages/dev';
import UserInterface from './pages/userInterface';
import Login from './pages/Login';

function App() {
  const userData = JSON.parse(sessionStorage.getItem("userData")) 
  const [LogInfo, SetLogInfo] = useState(userData ? (userData.is_dev ? "dev" : "user") : "notLoggedIn")


  function changeLoginState(state){
    SetLogInfo(state)
  }


  return (
    <div className="App">
      {LogInfo === "notLoggedIn" ? <Login changeLoginState={changeLoginState}/> : (LogInfo === "dev" ? <Dev />:<UserInterface />)}
    </div>
  );
}

export default App;
