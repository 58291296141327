import { createClient } from '@supabase/supabase-js'

// Create a single supabase client for interacting with your database

const url = 'https://ordzevciruwgyuynazcp.supabase.co'
const anon_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9yZHpldmNpcnV3Z3l1eW5hemNwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQ1NDUzMzQsImV4cCI6MjAzMDEyMTMzNH0.gVT35Nswuapim89n6Eo7UVBJSf55kbF_LzS3RxYCXAA'


export const supabase = createClient(url, anon_key);


