import React from 'react';

function Project(props) {
    const {name, status} = props
    let percent = 1

    switch (status) {
        case "waiting for approval":
            percent = 25
            break;
        case "ready for printing":
            percent = 50
            break;
        case "printing":
            percent = 75
            break;
        case "ready for pickup":
            percent = 100
            break;
        default:
            percent = 1
            break;
    }

    return (
        <div className="project">
            <h2>{name}</h2>
            <p className='status'>status: {status}</p>
            <div className="progress">
                <div className="progress-fill" style={{width: `${percent}%`}}></div>

            </div>
        </div>
    );
}

export default Project;