import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/helper/supabaseClient';

function Request(props) {
    const {nameOfModel, email, name, year, quantity, bucket_id, status, submitted, isStudent, id} = props.data
    const [modelForDownload, setModelForDownload] = useState()

    useEffect(() => {
        donwloadModel()
    }, [])

    async function donwloadModel(){
        const {data, error} = await supabase.storage.from('3dmodels').list(`student_models/${name}`, {
            limit: 10,
            offset: 0,
        })
        if (data){
            data.map(model => {
                const modelID = (model.name.split("--"))[0]
                if (bucket_id === modelID){
                    setModelForDownload(`https://ordzevciruwgyuynazcp.supabase.co/storage/v1/object/public/3dmodels/student_models/${name}/${model.name}`)
                }
            })
        }
        if (error){
            console.log(error)
        }
    }

    async function deleteRequest(){
        const { error } = await supabase
            .from('printers')
            .delete()
            .eq('id', id)
            if (error){
                console.log(error)
            }
        props.refresh()
    }

    async function moveRequest(){
        let newStatus;
        switch (status) {
            case "waiting for approval":
                newStatus = "ready for printing"
                break;
        
            case "ready for printing":
                newStatus = "printing"
                break;
        
            case "printing":
                newStatus = "ready for pickup"
                break;
        
            case "ready for pickup":
                deleteRequest()
                break;
            default:
                break;
        }

        
        const { data, error } = await supabase
        .from('printers')
        .update({ status: newStatus })
        .eq('id', id)
        if(error) console.log(error)                
        props.refresh()
    }

    return (
        <div className="request">
            <div className="model-main">
                <div className='left'>
                    <p className="model-name">{nameOfModel}</p>

                    <p className='model-info'><span className='bold'>name:</span> {name}</p>
                    <p className='model-info'><span className='bold'>submitted:</span> {submitted}</p>
                    <p className='model-info'><span className='bold'>quantity:</span> {quantity}</p>
                    <p className='model-info'><span className='bold'>email:</span> {email}</p>

                    {/* <button className='model-download left link' onClick={donwloadModel}>download model</button> */}
                    <a href={modelForDownload} className='model-download left link' download>download model</a>
                </div>
                <div className='tags'>
                    {!isStudent && <div className="teacher tag"><p>Mass order</p></div>}
                    {quantity > 5 && <div className="mass-order tag"><p>teacher</p></div>}
                </div>
            </div>

            <div className="model-btns">
                {status != "ready for pickup" && <button onClick={moveRequest} className='button model-btn'>Move up</button>}
                <button onClick={deleteRequest} className='button model-btn delete'>delete</button>
            </div>
        </div>
    );
}

export default Request;