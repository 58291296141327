import React, { useState } from 'react';
import { supabase } from '../lib/helper/supabaseClient';

function Login(props) {
    const [signUp, setSignUp] = useState(false)

    
    const [formData, setFormData] = useState({
        name: "",
        password: "",
    })

    const [formDataSignUp, setFormDataSignUp] = useState({
        name: "",
        password: "",
        email: "",
    })

    function onChange(e){
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    async function login(e){
        e.preventDefault()
        const {error, data} = await supabase
            .from('users')
            .select('*')
            if(data){
                data.map((user) => {
                    if(user.name === formData.name && user.password === formData.password){
                        sessionStorage.setItem("userData", JSON.stringify(user))
                        if (user.is_dev){
                            props.changeLoginState("dev")
                        }
                        else{
                            props.changeLoginState("user")
                        }
                    }
                })
            }
            if(error){
                console.log(error)
            }
    }

    async function signUpSumbit(e){
        e.preventDefault()
        const {data, error} = await supabase
        .from('users')
        .insert([{
            ...formDataSignUp,
            is_dev: false
        }])
        .select()
        if(error){
            console.log(error)
        }
        if (data){
            console.log("account made!")
            props.changeLoginState("user")
            sessionStorage.setItem("userData", JSON.stringify(formDataSignUp))
        }


    }

    function onChangeSignUp(e){
        setFormDataSignUp(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <div>
            {signUp ? <div className='signup'>
                <div className="login">
                    <h1>sign up</h1>
                    <form onSubmit={signUpSumbit}>
                        <input 
                            onChange={onChangeSignUp}
                            placeholder='name'
                            value={formDataSignUp.name}
                            type="text"
                            name='name'
                            className='light cen'
                        />
                        <input 
                            onChange={onChangeSignUp}
                            value={formDataSignUp.password}
                            placeholder='password'
                            type='password'
                            name='password'
                            className='light cen'
                        />
                        <input 
                            onChange={onChangeSignUp}
                            value={formDataSignUp.email}
                            placeholder='email'
                            type='email'
                            name='email'
                            className='light cen'
                        />
                        <button className='heavy'>Create account</button>
                        <button className='link' onClick={() => setSignUp(false)}>already have one?</button>
                    </form>
                </div>
            </div>: (
                <div className="login">
                    <h1>login</h1>
                    <form onSubmit={login}>
                        <input 
                            onChange={onChange}
                            placeholder='name'
                            value={formData.name}
                            type="text"
                            name='name'
                            className='light cen'
                        />
                        <input 
                            onChange={onChange}
                            value={formData.password}
                            placeholder='password'
                            type='password'
                            name='password'
                            className='light cen'
                        />
                        <button className='heavy'>Login</button>
                        <button className='link' onClick={() => setSignUp(true)}>don't have an account?</button>

                    </form>
                </div>
            )}
        </div>
    );
}

export default Login;