import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/helper/supabaseClient';
import {nanoid} from 'nanoid'
import Project from './Project';



function UserInterface() {
    const userData = JSON.parse(sessionStorage.getItem("userData")) 
    const [formData, setFormData] = useState({
        name: userData.name,
        email: userData.email,
        isStudent: true,
        year: "",
        nameOfModel: "",
        quantity: "",

    })

    const [modelFile, SetModelFile] = useState()
    const [formMSG, setFormMSG] = useState()
    const [projects, setProjects] = useState()

    
    useEffect(() => {
        getModels()
    }, [])

    function onChange(e){
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    async function onSubmit(e){
        e.preventDefault()
        const userID = nanoid()
        uploadModel(modelFile, userID)        

        const date = new Date
        const time = date.getDay() + "." + date.getMonth() + "." + date.getFullYear()
        const {data, error} = await supabase
            .from('printers')
            .insert([{
                ...formData,
                bucket_id: userID,
                status: "waiting for approval",
                submitted: time
            }])
            .select()
            if(error){
                console.log(error)
                setFormMSG("error! see console for more details")
            }
            if (data){
                setFormData({
                    name: userData.name,
                    email: userData.email,
                    isStudent: true,
                    year: "",
                    nameOfModel: "",
                    quantity: "",
                })
                setFormMSG("sucsess! keep an eye on 'your projects' for information")
            }


    }

    function changeStudent(isStudent){
        setFormData(prev => ({
            ...prev,
            isStudent: isStudent
        }))
    }


    function onFileChange(e){
        const file = e.target.files[0]
        SetModelFile(file)
    }

    
    async function uploadModel(model, modelID){        
        const {data, error} = await supabase
        .storage
        .from('3dmodels')
        .upload(`student_models/${formData.name}/${modelID}---${model.name}`, model)
        if (data){
        }
        if(error){
            console.log(error)
        }
    }

    async function getModels(){
        const {data, error} = await supabase 
        .from('printers')
        .select()
        if (error){
            console.log(error)
        }
        if (data){
            let arr = []
            data.map(request => request.name === userData.name && arr.push(request))
            setProjects(arr)
        }
    }   
    

    return (
        <div className="userInterface">
            <h1>Apply for a 3D print</h1>

            <form onSubmit={onSubmit}>
                <div className='form-row'>
                    <div className='form-student'>
                        <div onClick={() => changeStudent(true)} className={!formData.isStudent ? "light btn-form" : "heavy btn-form"}>Student</div>
                        <div onClick={() => changeStudent(false)} className={formData.isStudent ? "light btn-form" : "heavy btn-form"}>Teacher</div>
                        
                        {formData.isStudent ? <input 
                            placeholder='year'
                            name='year'
                            value={formData.year}
                            className='light'
                            onChange={onChange}
                            type='number'
                            required
                        />:
                        <div style={{width: 245}}></div>}
                    </div>
                </div>
                <div className="line"></div>
                <div className='form-row'>
                    <input 
                        placeholder='name of model'
                        name='nameOfModel'
                        value={formData.nameOfModel}
                        className='light'
                        onChange={onChange}
                        required
                    />
                    <input 
                        placeholder='quantity'
                        name='quantity'
                        value={formData.quantity}
                        className='light'
                        onChange={onChange}
                        type='number'
                        required
                    />
                </div>
                <div>
                <input type='file' className='light file-picker' onChange={(e) => onFileChange(e)} required/>
                </div>
                <button className='light'>submit</button>
            </form>
            {formMSG !== "" && <p className='form-msg'>{formMSG}</p>}

            <h2>my projects</h2>
            {projects && projects.map(project => <Project name={project.nameOfModel} status={project.status}/>)}
        </div>
    );
}

export default UserInterface;