import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/helper/supabaseClient';
import Request from '../Elements/Request';

function Dev() {
    const [allProjects, setAllProjects] = useState()
    useEffect(() => {
        
        fetchQue()
    }, [])

    async function fetchQue(){
        const {data, error} = await supabase 
        .from('printers')
        .select()
        if (error){
            console.log(error)
        }
        if (data){
            setAllProjects(data)
        }
    }   


    return (
        <div className="dev">
            <p>dev portal</p>
            <button onClick={fetchQue} className="refresh light">refresh</button>

            <div className="project-section">
                <h2>Waiting for approval</h2>
                {allProjects && <div>
                    {allProjects.map(request => request.status === "waiting for approval" && (<Request refresh={fetchQue} data={request}/>))}
                </div>}

            </div>

            <div className="project-section">
                <h2>Ready for printing</h2>
                {allProjects && <div>
                    {allProjects.map(request => request.status === "ready for printing" && (<Request refresh={fetchQue} data={request}/>))}
                </div>}
            </div>   
            <div className="project-section">
                <h2>Printing</h2>
                {allProjects && <div>
                    {allProjects.map(request => request.status === "printing" && (<Request refresh={fetchQue} data={request}/>))}
                </div>}
            </div>
            <div className="project-section">
                <h2>Ready for pickup</h2>
                {allProjects && <div>
                    {allProjects.map(request => request.status === "ready for pickup" && (<Request refresh={fetchQue} data={request}/>))}
                </div>}
            </div>
            </div>

    );
}

export default Dev;
